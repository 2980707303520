.copy-input {
  background: #e9ebec;
  color: #6c6f72;
  border: 3px solid #e9ebec;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 65%;
}

.copy-btn {
  width: 35%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #6c6f72;
  font-weight: 500;
  border: 3px solid #e9ebec;
  border-bottom-right-radius: 20px;
  border-start-end-radius: 20px;
  gap: 1px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .copy-btn {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    width: 100%;
  }
  .copy-input {
    display: none;
  }
}

.copy-btn:focus,
.copy-input:focus {
  outline: none;
}

.copy-btn {
  position: relative;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.copy-btn:hover {
  transition-duration: 0.1s;
}

.copy-btn:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px white;
}

.copy-btn:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.copy-btn:active {
  top: 1px;
}
